.AddMachine_container {

}

.AddMachine_header {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.AddMachine_header_title {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
}

.AddMachine_header_back {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-decoration: underline;
    color: #1F384C;
    cursor: pointer;
}

.AddMachine_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    padding: 30px;
}


.AddMachine_itemField {
    padding: 10px 0px;
    width: 100%;
}

.AddMachine_itemFieldLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.AddMachine_itemField input {
    border: 1px solid #55B962;
    /*box-shadow: 0px 0px 0px 2px #FFDE88;*/
    border-radius: 4px;
    /*color: black;*/
     background-color: rgba(242, 242, 242, 0.4);
    /* color: #fff !important; */
}

.AddMachine_itemField input.AddMachine_inputError {
    border: 1px solid #E46772;
}

.AddMachine_saveBtn {
    margin-top: 20px;
    background-color: #0C8CE9;
    color: #fff;
    border-radius: 4px;
    /*padding: 10px;*/
    height: 40px;
}

.AddMachine_saveBtn:hover {
    background-color: #0C8CE9;
    color: #fff;
}

.AddMachine_itemFieldError {
    color: #E46772;
    font-size: 14px;
    padding: 2px 0;
}
