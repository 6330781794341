.Config_Auth_container {
  height: 100%;
}

.Config_Auth_rowContainer {
  width: 100%;
  height: 100%;
}

.Config_Auth_rowContainer .ant-col {
  height: 100%;
  overflow-y: auto;
}

.Config_Auth_AuthForm {
  /* padding: 50px; */
  /* display: flex !important; */
  align-items: center;
  justify-content: center;
}

.Config_Auth_authTitle {
  display: flex;
  align-items: center;
}

.Config_Auth_itemField {
  padding: 10px 0px;
}

.Config_Auth_itemFieldLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2f3367;
}

.Config_Auth_itemField input {
  border: 1px solid;
  /*box-shadow: 0px 0px 0px 2px #FFDE88;*/
  border-radius: 4px;
  color: black;
  /* background-color: transparent; */
  /* color: #fff !important; */
}

.Config_Auth_itemField .ant-input-password {
  border: 1px solid;
  background-color: transparent !important;
  border-radius: 4px;
}

.Config_Auth_itemField .ant-input-password svg path {
  /* fill: #fff */
}

.Config_Auth_itemField .Config_Auth_itemFieldLabelCheckbox {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #afb6c2;
}

.Config_Auth_itemField .Config_Auth_itemFieldLabelCheckbox a {
  color: #afb6c2;
  text-decoration: underline;
}

.Config_Auth_itemField .Config_Auth_itemFieldError {
  color: #e46772;
  font-size: 14px;
  padding: 2px 0;
}

.Config_Auth_itemField input:-webkit-autofill,
.Config_Auth_itemField input:-webkit-autofill:hover,
.Config_Auth_itemField input:-webkit-autofill:focus,
.Config_Auth_itemField textarea:-webkit-autofill,
.Config_Auth_itemField textarea:-webkit-autofill:hover,
.Config_Auth_itemField textarea:-webkit-autofill:focus,
.Config_Auth_itemField select:-webkit-autofill,
.Config_Auth_itemField select:-webkit-autofill:hover,
.Config_Auth_itemField select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  /* -webkit-text-fill-color: #fff !important; */
  transition: background-color 5000s ease-in-out 0s;
  /*color: #fff!important;*/
}

.Config_Auth_authSubmit {
  margin-top: 20px;
  background: #007dfa !important;
  border-radius: 4px !important;
  width: 100%;
  border: none !important;
  box-shadow: none !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 18px;
}

.Config_Auth_authSubmit:hover {
  transform: scale(1.05);
  background-color: rgba(0, 125, 250, 0.761);
}

.Config_Auth_AuthImage {
  color: #fff;
  width: 100%;
  /* margin-left: 240px; */
  height: 100%;
}

.Config_Auth_authBlock {
  left: 20%;
  width: 60%;
  top: 25%;
  position: relative;
}

.Config_Auth_AuthImage {
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .Config_Auth_AuthForm {
    padding: 0;
    /* background: #292F4A; */
  }
  .Config_Auth_AuthImage {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .Config_Auth_AuthImage {
    display: none !important;
  }
}
