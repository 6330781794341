.Dashboard_container {

}


.Dashboard_col {
    padding: 20px;
}

.Dashboard_staBlock {
    background: #FFFFFF;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    position: relative;
}

.Dashboard_staBlockLinkA {
    color: #000000;
}

.Dashboard_header {
    display: flex;
    align-items: center;
}
.Dashboard_action {
    display: flex;
    align-items: center;
}
.Dashboard_staBlockLink {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.Dashboard_staBlockLink > div {
    padding: 0 5px;
}

.Dashboard_staBlockTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    padding: 10px 15px;
}

.Dashboard_addbtn {
    display: flex!important;
    align-items: center;
}

.Dashboard_addbtn span {
    margin-left: 5px;
}

.WarningMsg {
    font-weight: 400;
    font-size: 12px;
    line-height: 27px;
    padding: 10px 15px;
}

.Dashboard_staBlockAmount {
    flex: 1;
    font-weight: 700;
    font-size: 36px;
    line-height: 66px;
    color: #000000;
    padding: 0 10px;
}

.Dashboard_staBlockBottom {

}

.Dashboard_staBlockBottom img {
    width: 100%;
}

.Dashboard_table {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    padding: 20px;
    height: 100%;
    position: relative;
}

.Dashboard_table th.ant-table-cell {
    font-weight: 700;
}

.Dashboard_tableTitle {
    color: #0E132F;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.01em;
}

.Dashboard_favDetail {
    background: #0BD61F;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Dashboard_premium {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    padding: 20px;
    height: 100%;
}

.Dashboard_premiumText {
    color: #0E132F;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
}

.Dashboard_premiumText a {
    text-decoration: underline;
    color: #0E132F;
}

.Dashboard_premium img {
    /*width: 120px;*/
}

.Dashboard_premiumBtn {
    text-decoration: underline;
    cursor: pointer;
}

.Dashboard_staBlockBottomLink {
    display: none;
}

.Dashboard_col_tableLinkA {
    color: #000000;
}

.Dashboard_col_tableLink {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #878997;
}

.Dashboard_col_tableLink > div {
    padding: 0 5px;
}

.Dashboard_modal {
    min-width: 800px;
    max-width: 100vw;
    border-radius: 10px;
}


.Dashboard_modalDetail {
    min-width: 800px;
    max-width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*!*bottom: 0;*!*/
    background: #F7FAFC;
    border-radius: 10px;
    z-index: 100;
    padding: 20px 50px 20px 50px;
}

.Dashboard_modalAction {
    display: flex;
    justify-content: flex-end;
}

.Dashboard_modalDetailCol {
    display: flex!important;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Dashboard_modalClose {
    border: 2px solid #3182CE;
    border-radius: 10px;
    color: #3182CE;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Dashboard_like {
    border: 2px solid #3182CE;
    border-radius: 10px;
    color: #3182CE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 20px;
    margin: auto;
    text-transform: uppercase;
}

.Dashboard_modalDetailText1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #000000;
    margin-bottom: 20px;
}

.Dashboard_modalDetailText2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    padding: 10px 5px;
    color: #000000;
}

.Dashboard_modalDetailText3 {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #718096;
    padding-bottom: 5px;
}

.Dashboard_modalDetailView {
    background: #E2E8F0;
    border-radius: 5px;
    padding: 10px 20px;
    min-height: 42px;
    color: #718096;
}

.Dashboard_modalDetailViewLink {
    color: #718096!important;
}
.Dashboard_body {
    position: relative;
}

.Dashboard_detailBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.WarningMsgSpan {
    display: flex;
    margin-right: 10px;
    align-items: flex-end;
    justify-content: flex-end;
    color: red;
    font-weight: 600;
}



@media only screen and (max-width: 800px) {
    .Dashboard_col_table {
        display: none!important;
    }

    .Dashboard_premium {
        box-shadow: none;
        background-color: transparent;
    }


    .Dashboard_staBlockBottom {
        display: none;
    }

    .Dashboard_staBlockLink {
        display: none;
    }

    .Dashboard_staBlockBottomLink {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }
}


.maquina {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    transition: transform 100ms ease-in-out;
  }

  .maquina:hover{
    transform: scale(1.05);
  }

  .maquina img {
    max-width: 100%;
  }

  .maquina-info {
    padding: 20px;
  }
