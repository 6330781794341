.AddMaquina_header {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AddMaquina_header_title {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
}

.AddMaquina_header_back {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;
  color: #1f384c;
  cursor: pointer;
}

.AddMaquina_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding: 30px;
}

.AddMaquina_itemField {
  padding: 10px 0px;
  width: 100%;
}

.AddMaquina_itemFieldLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.AddMaquina_itemField input {
  border: 1px solid #55b962;
  /*box-shadow: 0px 0px 0px 2px #FFDE88;*/
  border-radius: 4px;
  /*color: black;*/
  background-color: rgba(242, 242, 242, 0.4);
  /* color: #fff !important; */
}

.ant-picker {
  width: 100%;
}

.AddMaquina_itemField input .AddMaquina_inputError {
  border: 1px solid #e46772;
}

.AddMaquina_saveBtn {
  margin-top: 20px;
  background-color: #0c8ce9;
  color: #fff;
  border-radius: 4px;
  /*padding: 10px;*/
  height: 40px;
}

.AddMaquina_saveBtn:hover {
  background-color: #0c8ce9;
  color: #fff;
}

.AddMaquina_itemFieldError {
  color: #e46772;
  font-size: 14px;
  padding: 2px 0;
}

.AddMaquina_Label_Icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.AddMaquina_Icon {
  width: 22px;
  margin-left: 10px;
  margin-bottom: 0.5rem;
}

.AddMaquina_Reload_Icon {
  cursor: pointer;
}
