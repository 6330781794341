.Main_container {
  height: 100%;
  width: 100%;
  display: flex;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.Main_sidebar {
  box-shadow: 1px 0px 24px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 80px;
  padding: 20px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Main_sidebar.open {
  width: 260px;
}

.sidebar-burger {
  width: 60px;
  height: 40px;
  display: grid;
  place-items: center;
  background-color: #f9f9f9;
  border: none; /* Remover a borda padrão */
  outline: none;
  margin-top: 5px;
  margin-left: 3px;
}

.Main_menuitemLink {
  color: #000000;
  margin-top: 50px;
  display: none;
}

.Main_menuitemLink.open {
  color: #000000;
  margin-top: 20px;
  display: flex;
}

.Main_menuitemLinkActive {
}

.Main_menuitemLinkActive .Main_menuitem {
  border-left: 5px solid #5a6acf;
}

.Main_menuitem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-left: 5px solid transparent;
}

.Main_menuitem img {
  width: 30px;
}

.Main_menuitem > div {
  padding-left: 5px;
}

.Main_right {
  flex: 1;
  /* margin-top: 10px; */
  margin-left: 30px;
}
.Main_header {
  display: none;
}

.Main_header.open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px !important;
  /* margin-top: 700px; */
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.Main_header_title {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.Main_content {
  height: calc(100vh - 80px);
  overflow-y: auto;
  /* margin-right: 100px; */
}

.Main_menuProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 3px;
}

.Main_menuProfile img {
  height: 50px;
}

.Main_dropdownIcon {
  width: 18px;
  height: auto !important;
}

.Main_header_title {
  display: none;
}
.Main_headerBetween {
}

.Main_headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Main_headerNoti {
  margin: 0 20px;
  cursor: pointer;
}

.Main_headerNoti img {
  height: 22px;
}
.Main_headerSearch {
  margin: 0 20px;
  cursor: pointer;
}
.Main_headerSearch img {
  height: 22px;
}

.SidebarMaquina {
  display: none;
}

.SidebarMaquina.open {
  display: flex;
}

@media only screen and (max-width: 850px) {
  .sidebar-burger {
    width: 60px;
    height: 40px;
    display: grid;
    place-items: center;
    background-color: #f9f9f9;
    border: none; /* Remover a borda padrão */
    outline: none;
    margin-top: 0px;
    margin-left: 7px;
  }

  .Main_menuitemLink.open {
    color: #000000;
    margin-top: 100px;
    margin-left: -95%;
    display: flex;
  }

  .Main_menuProfile {
    display: flex;
    cursor: pointer;
    margin-top: -10px;
    margin-left: 70%;
    /* margin-right: -20%; */
  }

  .Main_menuProfile .open {
    display: flex;
    cursor: pointer;
    margin-top: -10px;
    margin-left: 5px;
    /* margin-right: -20%; */
  }

  .Main_headerRight {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .Main_headerSearch {
    font-size: 12px;
    /* margin-left: 100px; */
  }

  .Main_menuitemLink {
    color: #000000;
    margin-top: -8px;
    margin-left: -30px;
  }

  .SidebarMaquina.open {
    display: flex;
  }

  .SidebarMaquina {
    display: flex;
  }

  .Main_menuitemLinkActive .Main_menuitem {
    border-left: 5px solid transparent;
  }
  .Main_sidebar.open {
    width: 100%;
    height: 30%;
  }

  .Main_container {
    display: block;
  }

  .Main_content {
    height: calc(100vh - 80px);
    overflow-y: auto;
    margin-left: 10px;
    /* margin-right: 100px; */
  }

  .Main_sidebar {
    box-shadow: 1px 0px 24px rgba(0, 0, 0, 0.1);
    /* position: absolute; */
    height: 12%;
    width: 100%;
    padding: 20px 0;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
  }
  .Main_right {
    width: calc(100vw - 80px);
  }

  .Main_header.open {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px !important;
    margin-top: 0px;
  }
  .Main_header_title {
    display: block;
  }
}

@media only screen and (max-width: 850px) and (orientation: landscape) {
  .Main_sidebar.open {
    height: 100%; /* Ajuste a altura conforme necessário */
  }
  .Main_sidebar {
    height: 25%;
  }

  .Main_menuProfile {
    display: flex;
    cursor: pointer;
    margin-top: -10px;
    margin-left: 80%;
    /* margin-right: -20%; */
  }

  .Main_header.open {
    display: flex;
    align-items: center;
    margin-left: 40%;
    justify-content: space-between;
    padding: 10px 40px !important;
    align-items: center;
    justify-content: center;
  }
}

.icon {
  /* color: #4169E1; */
  color: #5a6acf;
}

.circle-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 60px; /* Largura do círculo */
  height: 60px; /* Altura do círculo */
  border-radius: 50%; /* Para criar um círculo */
  background-color: #f1f2f7; /* Cor do círculo */
  color: rgb(89, 105, 207); /* Cor do texto dentro do círculo */
  font-size: 18px; /* Tamanho do texto */
}
